<template>
  <div>
    <md-result-page type="lost" :subtext="content"></md-result-page>
  </div>
</template>

<script>
  import { ResultPage } from 'mand-mobile'
  import ApplyApi from "@/api/apply";
	export default {
    name: "Goto",
    components: {[ResultPage.name]: ResultPage},
    props: {
      orderId: String,
      sign: String,
      time: String
    },
    data() {
      return {
        content: "内容加载中..."
      };
    },
    mounted() {
      this.validateLink()
    },
    methods: {
      validateLink() {
        ApplyApi
          .applyProduct(this.orderId, this.sign, this.time)
          .then((res) => {
            if (res.code == 200) {
              window.location.href = res.data
            }
          })
          .catch((error) => {
            this.content = error.msg
          });
      }
    }
	}
</script>